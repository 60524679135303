<template>
  <div  v-if="$isLogin && $isEmailVerified">
    <h1>Home</h1>
<!--  {{cookies}} -->
    <v-card>
      <v-card-title>Contents</v-card-title>
      <p><router-link to="/labels">Your Labels</router-link></p>
      <p><router-link to="/account">Account settings</router-link></p>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */

// @ is an alias to /src
//import firebase from "firebase/app";
//import "firebase/auth";
//import axios from 'axios';
//import "firebase/firestore";
import Cookies from 'js-cookie'

export default {
  name: 'home',
  components: {
//    HelloWorld
  },
  data: function () {
    return {
      cookies: Cookies.get()
    }
  },
  methods: {
  },
  computed: {
  },
  mounted() {

/*
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
      }
    })
*/
    Cookies.set('name', 'value', {expires: 10/86400})
    Cookies.set('死ね', 'ハゲ')
  },
  watch: {
  },
}
</script>
