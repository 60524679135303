<template>
  <v-app>
    <Navbar :links="links">
      <template v-slot:title>
      <v-toolbar-title class="grey--text" style="font-family: 'Parisienne', cursive;">
        <a 
          href="/hp"
          style="text-decoration: none;"
        >
          <span class="grey--text font-weight-bold">Magic</span>
          <span class="grey--text font-weight-light">Lable™</span>
        </a>
      </v-toolbar-title>
      </template>
    </Navbar>
    <div id="nav">
      <Login
        tosUrl="https://connect.uedasoft.com/docs/tos.html"
        privacyPolicyUrl="https://connect.uedasoft.com/docs/privacy.html"
      />
<!--     <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    </div>

    <v-main v-if="$isLogin">
      <router-view/>
    </v-main>

    <v-footer>
      <v-col
        class="text-center"
      >
        <a 
          href="https://atelierueda.uedasoft.com"
          style="text-decoration: none;"
          class="grey--text"
        >
        © Atelier UEDA
        </a>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import {Navbar} from 'vuetify-nav'

import {AccountRoutes  as AccountRoutes} from 'vue2-faui-user-fe/account'
import {PurchaseRoutes as PurchaseRoutes} from 'vue2-faui-user-fe/purchase'
import {LoginCDN as Login} from 'vue2-faui-user-fe/loginCDN'

export default {
  name: 'App',

  components: {
    Navbar,
    Login,
  },
  data: () => ({
    links: [
      { icon: 'home', text: 'Home', route: '/'},
      { icon: 'nfc', text: 'Labels', route: '/labels'},
      AccountRoutes.menuItem,
      PurchaseRoutes.menuItem,
      { icon: 'menu_book', text: 'Document', route: '/documents'},
    ]
    //
  }),
  created(){
//    AccountRoutes.init()
  }
};
</script>
