import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

//import Purchase from '@/views/purchase/purchase.route.js'

import {AccountRoutes  as AccountRoutes} from 'vue2-faui-user-fe/account'
import {PurchaseRoutes as PurchaseRoutes} from 'vue2-faui-user-fe/purchase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
/*
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" *//* '../views/About.vue')
  },
*/
//  {
//    path: '/account',
//    name: 'account',
//    component: () => import(/* webpackChunkName: "about" */ '@/views/Account.vue')
//  },
  {
    path: '/documents',
    name: 'documents',
    beforeEnter() {location.href = '/docs/guide'}
  },
  {
    path: '/hp',
    name: 'hp',
    beforeEnter() {location.href = '/docs'}
  },
  {
    path: '/register/:id',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Register.vue')
  },
  {
    path: '/edit/:externalID',
    name: 'edit',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Edit.vue')
  },
  {
    path: '/labels',
    name: 'labels',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Labels.vue')
  },
/*
  {
    path: '/labels01',
    name: 'labels01',
    component: () => import(/* webpackChunkName: "about" *//* '@/views/Labels01.vue')
  },
*/

  // purchase
  //...Purchase.routes,

  ...AccountRoutes.routes,
  ...PurchaseRoutes.routes,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
