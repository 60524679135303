/* eslint-disable no-console */
// https://qiita.com/saio-th/items/111f6f5cc62f421cf045
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
//import firebase from 'firebase'
import VueGtag from "vue-gtag"
//import VueCookies from 'vue-cookies'
//import fbinit from 'vue2-faui-user-fe/fbinitCDN'
import initialize from 'vue2-faui-user-fe/initializeCDN'

Vue.config.productionTip = false

// gtag Auto tracking
Vue.use(VueGtag, {
  config: { id: "UA-43410269-6" }
}, router);
//Vue.use(VueCookies)

let globalData = new Vue({
  data: { 
    $server: process.env.VUE_APP_serverProtocol + "://" 
             + process.env.VUE_APP_serverHost +"."
             + process.env.VUE_APP_serverDomain,
  }
});

//fbinit.fbinit()

let computed = {
  $server: {
    get: function () { return globalData.$data.$server },
  },
  ...initialize.accountinit(false),
  ...initialize.purchaseinit()
}
console.log("computed:", computed)

Vue.mixin({
  computed: computed
})

// https://stackoverflow.com/questions/49256765/change-vue-prototype-variable-in-all-components
/*
let globalData = new Vue({
  data: { 
    $stripe: process.env.VUE_APP_stripeEnv == "live" ? process.env.VUE_APP_stripeapiLiveKey : process.env.VUE_APP_stripeapiTestKey,
    $stripeEnv: process.env.VUE_APP_stripeEnv,
  }
});
console.log(globalData)

Vue.mixin({
  computed: {
    $stripe: {
      get: function () { return globalData.$data.$stripe },
    },
    $stripeEnv: {
      get: function () { return globalData.$data.$stripeEnv },
    },
  }
})
*/

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')